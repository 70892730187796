// client/src/components/AppShell/SignUpButton.component.tsx
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useAuthStatus } from './hooks/useAuthStatus';
import { useAuthActions } from './hooks/useAuthActions';

export const SignUpButton: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const { onLoginSubmit, buttonWasClicked } = useAuthActions();
  const isLoading = useAuthStatus(inProgress);

  if (isAuthenticated) {
    return null; // Already signed in, don't show the sign up button
  }

  return (
    <Button
      aria-label="Sign Up"
      variant="contained"
      onClick={onLoginSubmit}
      color="primary"
      disabled={isLoading}
      startIcon={
        isLoading && buttonWasClicked ? <CircularProgress size={20} /> : null
      }
    >
      Sign Up
    </Button>
  );
};
