import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useAuthStatus } from './hooks/useAuthStatus';
import { useAuthActions } from './hooks/useAuthActions';

export const SignInOrOutButton: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const { onLoginSubmit, onLogoutSubmit, buttonWasClicked } = useAuthActions();
  const isLoading = useAuthStatus(inProgress);

  const buttonText = isAuthenticated ? 'Sign Out' : 'Sign In';

  return (
    <Button
      aria-label={buttonText}
      variant="contained"
      onClick={isAuthenticated ? onLogoutSubmit : onLoginSubmit}
      color="secondary"
      disabled={isLoading}
      startIcon={
        isLoading && buttonWasClicked ? <CircularProgress size={20} /> : null
      }
    >
      {buttonText}
    </Button>
  );
};
