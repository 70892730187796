import { useMsal } from '@azure/msal-react';
import { useEventCallback } from '@mui/material';
import { useState } from 'react';
import { BrowserUtils } from '@azure/msal-browser';
import { loginRequest } from '../../../constants/authConfig';

export const useAuthActions = () => {
  const { instance } = useMsal();
  const [buttonWasClicked, setButtonWasClicked] = useState(false);

  const onLoginSubmit = useEventCallback(async () => {
    setButtonWasClicked(true);
    instance.loginRedirect(loginRequest);
  });

  const onLogoutSubmit = useEventCallback(() => {
    setButtonWasClicked(true);
    instance.logoutRedirect({
      account: instance.getActiveAccount(),
      onRedirectNavigate: () => !BrowserUtils.isInIframe(),
    });
  });

  return {
    onLoginSubmit,
    onLogoutSubmit,
    buttonWasClicked,
  };
};
